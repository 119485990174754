import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
    cardarea: {
        boxShadow: "0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a",
        borderRadius: 10,         
    },
    cardarea2: {
        boxShadow: "0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 25
    }, 
    detailnew: {
        backgroundColor: "#2642D3",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#2642d3d4",
        },
    },
    profilearea: {
        display: "flex",
        justifyContent: "space- between"
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    coursenroll: {
        color: "#000",
        fontSize: "16px!important",
        lineHeight: "30px",
    },
    coursen: {
        color: "#000",
        fontWeight: "600!important",
        fontSize: "16px!important",
        lineHeight: "30px",
    },
    newcard: {
        textAlign: "center!important",
    },
    detailnew2: {
        marginTop:20,
        backgroundColor: "#2642D3",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#2642d3d4",
        },
    }
}));


import React,{Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import axios from 'axios';
import SimpleReactValidator from "simple-react-validator";
import Alert from '@material-ui/lab/Alert';
import {history} from "./shared/history/history";
import CircularProgress from '@material-ui/core/CircularProgress';

import API from "./shared/constants";

class Login extends Component {
  constructor()
  {
    super();
    this.state={
        email:"",
        password:"",
        loading: false
      }
      this.validator = new SimpleReactValidator({autoForceUpdate:this});
  }

  componentDidMount()
  {
    let checklogin=localStorage.getItem('token');
    !checklogin ? this.props.history.push('/login'): this.props.history.push('/app/dashboard')
  }

  handleFormSubmit = e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let data = {
         email: this.state.email,
          password: this.state.password
      };
      let config={
        headers:
        {
            "Content-type":"application/json"
        }
    }
      axios.post(`${API}/login`,JSON.stringify(data),config)
            .then(res=>{
                localStorage.setItem('token',res.data.token);
                localStorage.setItem('usertype',res.data.user_type);
                localStorage.setItem('userid',res.data.id);
                
                  setTimeout(() => {
                    history.push("/app/dashboard");
                    window.location.reload();
                  }, 200);
            })
            .catch(err=>{
                const loginstatus=err.response.data.status;
                setTimeout(() => {
                this.setState({loginstatus})
              }, 200);
            })
    } 
    else {
      this.forceUpdate();
      this.validator.showMessages();
    }
    this.toggleLoading();
  };

  toggleLoading = () => {
    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }

  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render()
  {
    const { loading } = this.state;
  return (
    <>
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-md-7 d-none d-md-flex bg-image">
          </div>
            <div className="col-md-5 bg-light">
              <div className="login d-flex align-items-center py-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 col-xl-9 mx-auto">
                      <div className="MuiAvatar-root MuiAvatar-circle jss40 MuiAvatar-colorDefault"><svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path></svg></div>
                      <h4 className="text-muted mb-4 text-center">Login</h4>
                      <form onSubmit={this.handleFormSubmit}>
                        <div className="form-group mb-3">
                          <input id="inputEmail" type="email" placeholder="Email address" 
                          required="" autoFocus="" name="email" value={this.state.email}
                          onChange={this.handleInput}
                          className="form-control rounded-pill border-0 shadow-sm px-4" />
                        </div>
                        {
                            this.validator.message('email',this.state.email,'required|email')
                        }
                        <div className="form-group mb-3">
                          <input id="inputPassword" type="password" placeholder="Password" 
                          required="" name="password" value={this.state.password}
                          onChange={this.handleInput}
                          className="form-control rounded-pill border-0 shadow-sm px-4 text-primary" />
                        </div>
                        {
                            this.validator.message('password',this.state.password,'required|min:8')
                        }
                        {/*<div className="custom-control custom-checkbox mb-3">
                          <input id="customCheck1" type="checkbox" className="custom-control-input" />
                          <label htmlFor="customCheck1" className="custom-control-label">Remember password</label>
                      </div>*/}
                            <button type="submit" className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm px-4 logbutton"
                            onClick={this.handleFormSubmit} disabled={loading}>
                              {loading && (<CircularProgress size={18} className="buttonProgress" />)}
                              Sign in</button>
                            
                      </form>
                      {
                        this.state.loginstatus ? <Alert severity="error">{this.state.loginstatus}</Alert> : null
                      }
                      <div className="links">
                        {/*<div className="float-left"><a href="/forgotpassword" className="link-primary">Forgot password?</a></div>*/}
                        <div className="float-right"><a href="/signup" className="link-primary">Don't have an account? Sign Up</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
  }
}

export default Login;

import React, {useEffect,useState} from "react";

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField
} from '@material-ui/core';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from "./styles";
import axios from 'axios';
import pic from "../../upload/pic.png";
import { Typography } from "../../components/Wrappers/Wrappers";
import Fpassword from "../fpassword/Fpassword";

import API from "../../shared/constants";

export default function ProfilePage(props) {
    var classes = useStyles();
    const [userdata, setUserdata] = useState("");
    const [loading, setLoading] = useState(true);
    const [session, setsession] = useState(false);


    function handleClick() {
        setsession(true);
    }

    function getback() {
        setsession(false);
    }

    useEffect(() => {
        const userid = JSON.parse(localStorage.getItem('userid'));
        const usertoken = localStorage.getItem('token');
        let config = {
            headers:
            {
                "Content-type": "application/json",
                Authorization: `Bearer ${usertoken}`
            }
        }

        axios.get(`${API}/student/users/${userid}`, config)
            .then(res => {
                setUserdata(res.data.user);
                setName(res.data.user.name);
                setPhone(res.data.user.phone);
                setLoading(false);
            }
            )
            .catch(err => err.response)
    }, []);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const handleFormData = e => {
        const userid = JSON.parse(localStorage.getItem('userid'));
        const usertoken = localStorage.getItem('token');
        e.preventDefault();
        if (!name && !phone) { return null; }
        let data = {
            name: name,
            phone: phone,
        };
        let config = {
            headers:
            {
                "Content-type": "application/json",
                Authorization: `Bearer ${usertoken}`
            }
        }
        axios.post(`${API}/student/edit_student/${userid}`, JSON.stringify(data), config)
            .then(res => {
                updateData();
                setLoading(false);
            }
            )
            .catch(err => err.response)
    }

    const updateData=()=> {
        props.history.push("/app/profile");
        window.location.reload();
    }
    return (
        <>
            <PageTitle title="Profile" />
            {
                session === true ? <Fpassword getback={getback} data={userdata} /> :
                    <>
            <Grid className={classes.profilearea} container spacing={8}>
                            <Grid
                                item
                                lg={8}
                                xs={12}
                            >
                                <form onSubmit={handleFormData}>
                                    <Card className={classes.cardarea} >
                                        <CardHeader
                                            subheader="The information can be edited"
                                        />

                                        <Divider />
                                        <CardContent>
                                            <Grid
                                                container
                                                spacing={3}
                                            >
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        name="id"
                                                        variant="outlined"
                                                        disabled
                                                        value={userdata.id}

                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Name"
                                                        name="name"
                                                        variant="outlined"
                                                        value={name}
                                                        onChange={e => setName(e.target.value)}

                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        name="email"
                                                        variant="outlined"
                                                        disabled
                                                        value={userdata.email}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={6}
                                                    xs={12}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="Phone Number"
                                                        name="phone"
                                                        type="tel"

                                                        variant="outlined"
                                                        value={phone}
                                                        onChange={e => setPhone(e.target.value)}

                                                    />
                                                </Grid>

                                            </Grid>
                                        </CardContent>
                                        <Divider />
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            p={2}
                                        >
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className={classes.detailnew}
                                                onClick={handleFormData}
                                            >
                                                Update details
          </Button>
                                        </Box>
                                    </Card>
                                </form>
                            </Grid>
                <Grid
                    item
                    lg={4}
                    xs={12}
                >
            <Card className={classes.cardarea2}>
                        <img src={pic}
                            title="student"
                            height="100px"
                            alt="student"
                        />
                        <CardContent className={classes.newcard}>
                    <Typography component="h5" variant="h4" className={classes.coursenroll} noWrap>
                        <span className={classes.coursen}>ID :</span> {userdata.id}</Typography>
                    <Typography component="h5" variant="h4" className={classes.coursenroll} noWrap>
                        <span className={classes.coursen}>Name :</span> {userdata.name}</Typography>
                    <Typography component="h5" variant="h4" className={classes.coursenroll} noWrap>
                        <span className={classes.coursen}>Email :</span> {userdata.email}</Typography>
                    <Typography component="h5" variant="h4" className={classes.coursenroll} noWrap>
                                <span className={classes.coursen}>Phone :</span> {userdata.phone}</Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.detailnew2}
                                onClick={handleClick}
                            >
                                Change Password
          </Button>
                </CardContent>
            </Card>
        </Grid>
                        </Grid>
                    </>
            }
        </>
    );
}

import React,{useEffect,useState} from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import axios from 'axios';
// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

import API from "../../shared/constants";

// pages
import Dashboard from "../../pages/dashboard";
import Courses from "../../pages/courses/Courses";
import Profile from "../../pages/profile/Profile";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
    var classes = useStyles();
    const [users, setUsers] = useState("");
  // global
  var layoutState = useLayoutState();

    useEffect(() => {
        let userid = JSON.parse(localStorage.getItem('userid'));
        let usertoken = localStorage.getItem('token');

        let config = {
            headers:
            {
                "Content-type": "application/json",
                Authorization: `Bearer ${usertoken}`
            },
        }

        axios.get(`${API}/student/users/${userid}`, config)
            .then(res => {
                const userdata = res.data.user.name;
                setUsers(userdata);
            }
              )
    .catch(err=>err.response)
  }, []);
  
    return (
    <div className={classes.root}>
        <>
          <Header history={props.history} userd={users}/>
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/courses" component={Courses} />
              <Route path="/app/profile" component={Profile} />
            </Switch>
          
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);

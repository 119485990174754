import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";

import logo2 from "../../upload/logo.png";
// styles
import useStyles from "./styles";

// components
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";


export default function Header(props) {
  var classes = useStyles();
    const history = useHistory();
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  //var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [profileMenu, setProfileMenu] = useState(null);

  function signOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("usertype");
      localStorage.removeItem("userid");
    history.push("/login");
    window.location.reload();
  }

    function profile() {
            history.push("/app/profile");
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <img src={logo2} className="img-fluid" alt="Logo" width="60px"></img>
        <div className={classes.grow} />
              <Avatar className={classes.large} aria-haspopup="true"
                  color="inherit"
                  className={classes.headerMenuButton}
                  aria-controls="profile-menu"
                  onClick={e => setProfileMenu(e.currentTarget)}>
                  {JSON.stringify(props.userd).slice(1, 2)}
              </Avatar>
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
        </Menu>
        
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          
          <MenuItem
                      className={classNames(
                          classes.profileMenuItem,
                          classes.headerMenuItem,
                      )} onClick={profile}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )} onClick={signOut}
          >
              <ExitToAppIcon className={classes.profileMenuIcon} />
              Sign Out
            </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

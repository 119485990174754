import React, {useEffect, useState} from "react";

import { Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import axios from 'axios';
import {encode} from 'base-64';

import General_English from "../../upload/General_English.png";
import IELTS from "../../upload/IELTS.png";

import API from "../../shared/constants";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import { SignalCellularNullOutlined } from "@material-ui/icons";

export default function Dashboard(props) {
  var classes = useStyles();
  //var theme = useTheme();

  const [areCourses, setAreCourses] = useState(false);
  const [courses, setCourses] = useState([]);
  const [student, setStudent] = useState({});
  const [enrolledCourses, setEnrolledCourses] = useState([]);


  const newEnroll = courseNo => {
    if(courses === []) {
      alert('Courses not loaded, Please try agian later!');
      return;
    }

    var coursePrice = parseInt(courses[courseNo - 1].price)*100;


    let config={
      headers:
      {
          "Content-type":"application/json",
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }
    let body = {
      amount: coursePrice,
    }
    axios.post(`${API}/student/create_order`, JSON.stringify(body), config)
    .then(res => {
      var options = {
        "key": "rzp_live_AdbfaSSgdBfjfB", 
        "amount": coursePrice,
        "currency": "INR",
        "name": "Little Letters Linked",
        "description": `${courses[courseNo - 1].name} Course`,
        "order_id": res.data.orderid,
        "handler": function (response){
          saveCourse(response, courseNo);
        },
        "prefill": {
            "name": student !== {} ? student.name : '',
            "email": student !== {} ? student.email : '',
            "contact": student !== {} ? student.phone : ''
        },
        "theme": {
            "color": "#000"
        }
      };
      const rpayment = new window.Razorpay(options);
      rpayment.open();
    })
    .catch(err => {
      alert("Couldn't load the enrollment details, Please try again later!")
    })
  }

  const saveCourse = (response, courseNo) => {
    let conf={
      headers:
      {
          "Content-type":"application/json",
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }
    let data = {
      student_id: student !== {} ? student.id : localStorage.getItem('userid'),
      batch_id: "-",
      course_id: courseNo,
      teacher_id: "-",
      completed: 0,
      rz_payment_id: response.razorpay_payment_id,
      rz_order_id: response.razorpay_order_id,
      rz_signature_id: response.razorpay_signature
    }
    axios.post(`${API}/student/enroll`, JSON.stringify(data), conf)
      .then(res => {
        checkEnrolledCourses();
        alert('Course has been purchased and added to your Account');
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);
      })
  }

  useEffect(() => {
    checkEnrolledCourses()
    getCourses()
    getStudentInfo()
  }, [])

  const checkEnrolledCourses = () => {
    let config={
      headers:
      {
          "Content-type":"application/json",
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }
    axios.get(`${API}/student/enrolled_courses`,config)
      .then(res => {
        if(res.data.student_courses.length !== 0) {
          //setAreCourses(true)
          setEnrolledCourses(res.data.student_courses);
        }
        if(res.data.student_courses.length !== 0) {
          setAreCourses(true)
        }
      })
      .catch(err=>{
          console.log(err);
      })
  }

  const getCourses = () => {
    let config={
      headers:
      {
          "Content-type":"application/json",
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }
    axios.get(`${API}/student/courses`,config)
      .then(res=>{
        if(res.data) {
          setCourses(res.data.courses);
        }
      })
      .catch(err=>{
          alert( `Couldn't load Courses. Try again Later!`);
      })
  }

  const getStudentInfo = () => {
    let config={
      headers:
      {
          "Content-type":"application/json",
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }
    axios.get(`${API}/student/users/${localStorage.getItem('userid')}`,config)
      .then(res=>{
        if(res.data) {
          setStudent(res.data.user);
        }
      })
      .catch(err=>{
          console.log(err.response);
      })
  }

  const onDetails = courseNo => {
    if(courseNo === 1) {
      window.open("https://littleletterslinked.com/general-english/", "_blank");
    } else if(courseNo === 2) {
      window.open("https://littleletterslinked.com/ielts/", "_blank");
    }
  }

  return (
    <>
      <PageTitle title="Dashboard" />

      { areCourses ? 
      <>
      <Typography variant="h4" className={classes.course}>Enrolled Courses : {enrolledCourses.length === 1 ? enrolledCourses[0].course[0].name : enrolledCourses.length === 2 ? `${enrolledCourses[0].course[0].name}, ${enrolledCourses[1].course[0].name}` : SignalCellularNullOutlined}</Typography>
      <Typography gutterBottom variant="h6" component="h2" style={{ fontWeight: 600 }}>Instructions</Typography>
      <Typography variant="h6" component="h2" style={{ fontWeight: 400, marginTop: 10 }}>1. Download Microsoft Teams from <a target="_blank" href="https://www.microsoft.com/en-in/microsoft-teams/download-app">here</a>.</Typography>
      <Typography variant="h6" component="h2" style={{ fontWeight: 400, marginTop: 10 }}>2. You will get an email for joining the Teams in next 24 hours.</Typography>
      <Typography variant="h6" component="h2" style={{ fontWeight: 400, marginTop: 10 }}>3. That's it. Wait for the invite and hop onto the learning journey!</Typography>
        <br></br>
      <Typography variant="h6" component="h2" style={{ fontWeight: 400 }}>Happy Learning!!!</Typography>
      {/*<Typography variant="h4" className={classes.course}>Course : General English</Typography>
      <Grid container spacing={4}>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title="Details"
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
                  <Typography className={classes.coursetext} noWrap>Name : General English</Typography>
                  <Typography className={classes.coursetext} noWrap>Enrolled : 5/03/2021</Typography>
                  <Typography className={classes.coursetext} noWrap>Status :<span className={classes.colortext1}> Active</span></Typography>
                </Grid>
              </Grid>
            </div>
          </Widget>
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title="Batch"
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
              <Typography className={classes.coursetextmain} noWrap>GE03</Typography>
                </Grid>
              </Grid>
            </div>
          </Widget>
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title="Sessions Covered"
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
              <Typography className={classes.coursetextmain} noWrap>18/30</Typography>
                </Grid>
              </Grid>
            </div>
          </Widget>
        </Grid>

        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title="Next Session"
          >
            <div className={classes.visitsNumberContainer}>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
                  <Typography className={classes.coursetext} noWrap>Date : 6/04/2021</Typography>
                  <Typography className={classes.coursetext} noWrap>Time : 6:00 PM</Typography>
                  <Typography className={classes.coursetext} noWrap>Link : <span className={classes.colortext2}>Join Now</span></Typography>
                </Grid>
              </Grid>
            </div>
          </Widget>
        </Grid>
      </Grid>*/}
      </> : null
      }

      <br></br>


      <Typography variant="h4" className={classes.courseenroll}>Courses to Enroll</Typography>

      
        <Grid container spacing={4}>
        { enrolledCourses.some(enrolledcourse => enrolledcourse.course_id === "1") ? null :
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Grid item xs={12}>
          <Card className={classes.cardarea}>
            <CardMedia
            component="img"
            alt="course1"
            height="300"
            image={General_English}
            title="General English"
            />
            <CardContent className={classes.coursetitle2}>
              <Typography gutterBottom variant="h6" component="h2" style={{ fontWeight: 600 }}>General English</Typography>
            </CardContent>
            <CardContent className={classes.coursetitle3}>
              <Typography variant="h6" component="h2" style={{ fontWeight: 400 }}>₹ 3,999</Typography>
            </CardContent>
            <CardActions className={classes.actionbutton}>
              <Button onClick={() => onDetails(1)} size="small" className={classes.details} variant="contained">Details</Button>
              <Button size="small" className={classes.enroll} variant="contained" onClick={() => newEnroll(1)}>Enroll</Button>
            </CardActions>
          </Card>
          </Grid>
        </Grid>
        }

        { enrolledCourses.some(enrolledcourse => enrolledcourse.course_id === "2") ? null :
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Grid item xs={12}>
          <Card className={classes.cardarea}>
            <CardMedia
              component="img"
              alt="course2"
              height="300"
              image={IELTS}
              title="IELTS"
            />
            <CardContent className={classes.coursetitle2}>
              <Typography gutterBottom variant="h6" component="h2" style={{ fontWeight: 600 }}>IELTS</Typography>
            </CardContent>
            <CardContent className={classes.coursetitle3}>
              <Typography variant="h6" component="h2" style={{ fontWeight: 400 }}>₹ 3,999</Typography>
            </CardContent>
            <CardActions className={classes.actionbutton}>
              <Button onClick={() => onDetails(2)} size="small" className={classes.details} variant="contained">Details</Button>
              <Button size="small" className={classes.enroll} variant="contained" onClick={() => newEnroll(2)}>Enroll</Button>
            </CardActions>
          </Card>
          </Grid>
        </Grid>
        }
      </Grid>
        <br></br>
      {enrolledCourses.length === 2 ? 
      <Typography gutterBottom variant="h6" component="h2" style={{ fontWeight: 400, marginLeft: 5 }}>No new courses yet!</Typography>
      : null}

    </>
  );
}

import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  course:{
    marginBottom:30,
  },
  courseenroll:{
    marginTop:20,
    marginBottom:30,
  },
  actionbutton:{
    padding: "0px 25px 35px 25px",
    justifyContent: "space-around",
  },
  details: {
    backgroundColor:"#2642D3",
    color:"#fff",
    "&:hover": {
      backgroundColor: "#2642d3d4",
    },
  },
  enroll: {
    backgroundColor:"#F43A3F",
    color:"#fff",
    "&:hover": {
      backgroundColor: "#f43a3fd1",
    },
  },
  coursetitle:{
    padding: "16px 25px",
    color:"#000000",
  },
  coursetitle2:{
    padding: "0px 25px",
    paddingTop: "15px", 
    color:"#000000",
  },
  coursetitle3:{
    padding: "0px 25px",
    paddingBottom: "15px",
    color:"#000000",
  },
  cardarea:{
    boxShadow: "0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a",
    borderRadius:18,
  },
  coursetext:{
    color:"#000000",
    fontSize:"16px!important",
    lineHeight:"28px",
    fontWeight:"500!important",
  },
  coursetextmain:{
    color:"#000000",
    fontWeight:"600!important",
    fontSize:"40px!important",
    marginTop: 12,
  },
  colortext1:{
      color:"#F43A3F",
  },
  colortext2:{
    color:"#2642D3",
}
}));

import React, {useEffect} from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route,Switch,Redirect} from "react-router-dom";
import Signup from "./register";
import Login from "./login";
import Layout from "./components/Layout";
import { useUserState } from "./context/UserContext";

const App =()=>{
    var { isAuthenticated } = useUserState();

    useEffect(() => {
      document.title = "Little Letters Linked"
   }, []);

  return (
    <>
      <Switch>
        <Route path="/" exact component={Login}/>
        <PublicRoute path="/login" exact component={Login}/>
        <PublicRoute path="/signup" exact component={Signup}/>
        <PrivateRoute
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PrivateRoute path="/app" component={Layout} />
      </Switch>
    </>
  );
  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
  }


export default App;
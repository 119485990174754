import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
 
  course:{
    marginBottom:30,
    marginTop:30,
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  coursenroll:{
    color:"#000",
    fontSize:"16px!important",
    lineHeight:"30px",
  },
  coursen:{
    color:"#000",
    fontWeight:"600!important",
    fontSize:"18px!important",
    lineHeight:"30px",
  },
  coursetext:{
    color:"#000000",
    fontSize:"16px!important",
    lineHeight:"28px",
    fontWeight:"500!important",
  },
  colortext1:{
    color:"#F43A3F",
    fontWeight:"600!important",
},
datacardtable:{
  display: "flex",
  justifyContent: "space-between",
  border: "1px solid #000000",
  padding: "10px 25px",
  borderRadius: "10px",
  marginBottom: "20px",
  background: "#fff",
},
tabbackground:{
  background:"#f1f1f1",
},
detailnew:{
  backgroundColor:"#2642D3",
    color:"#fff",
    "&:hover": {
      backgroundColor: "#2642d3d4",
    },
},
tabnamebg:{
  color: "#000!important",
  background: "#f1f1f1!important",
  opacity:0.9,
  "&:hover": {
    color: "#000!important",
    textDecoration:"none!important",
    background: "#f1f1f1!important",
  },
}
}));

import React, {useState} from "react";

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid
} from '@material-ui/core';

// components
import useStyles from "./styles";
import axios from 'axios';

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import API from "../../shared/constants";

import pic from "../../upload/pic.png";
import { Typography } from "../../components/Wrappers/Wrappers";


export default function FpaswwordPage(props) {
    var classes = useStyles();

    const [oldpassword, setOldpassword] = useState("");
    const [newpassword, setNewpassword] = useState("");
    const [cpassword, setCpassword] = useState("");

    const [oldShowPasswordValue, setOldShowPasswordValue] = useState({ showPassword: false });
    const [newShowPasswordValue, setNewShowPasswordValue] = useState({ showPassword: false });
    const [cShowPasswordValue, setCShowPasswordValue] = useState({ showPassword: false });

    const handleShowPassword = () => {
        setOldShowPasswordValue({ showPassword: !oldShowPasswordValue.showPassword })
    }
    const handleShowPassword2 = () => {
        setNewShowPasswordValue({ showPassword: !newShowPasswordValue.showPassword })
    }
    const handleShowPassword3 = () => {
        setCShowPasswordValue({ showPassword: !cShowPasswordValue.showPassword })
    }

    const handleFormData = e => {
        const userid = JSON.parse(localStorage.getItem('userid'));
        const usertoken = localStorage.getItem('token');
        e.preventDefault();
       if (cpassword === "" && newpassword === "") {
            alert("This field is required");
        }
         else if (cpassword !== newpassword) {
            alert("Confirm Passwords don't match");
        }
        else{
            let data = {
                oldpassword: oldpassword,
                newpassword: newpassword,
            };
            let config = {
                headers:
                {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${usertoken}`
                }
            }
             axios.post(`${API}/student/change_password/${userid}`, JSON.stringify(data), config)

                .then(res => {
                    alert("Password changed successfully");
                    updateData();
                })
                .catch(err => {
                    alert("Please enter correct current password");
                })
        }
    }

    const updateData=()=> {
        //props.history.push("/app/profile");
        window.location.reload();
    }
    return (
        <>
            <Grid className={classes.profilearea} container spacing={8}>
                            <Grid
                                item
                                lg={8}
                                xs={12}
                            >
                                <form onSubmit={handleFormData}>
                    <Card className={classes.cardarea} >
                        <div className={classes.together}>
                                        <CardHeader
                                            subheader="The Password can be Changed here"/>
                            <Button
                            color="primary"
                            variant="contained"
                            className={classes.detailnew2}
                            onClick={props.getback}>
                            Edit Information
                            </Button>
                            </div>
                                        <Divider />
                                        <CardContent>
                                            <Grid
                                                container
                                                spacing={3}
                                            >
                                                <Grid
                                                    item
                                                    md={12}
                                                    xs={12}
                                >


                                    <FormControl variant="outlined" fullWidth name="oldpassword" required
                                        value={oldpassword} onChange={e => setOldpassword(e.target.value)}>
                                        <InputLabel htmlFor="outlined-adornment-password">Current Password</InputLabel>
                                        <OutlinedInput
                                            labelWidth={140}
                                            type={oldShowPasswordValue.showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleShowPassword}
                                                        edge="end"
                                                    >
                                                        {oldShowPasswordValue.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                   
                                        />
                                    </FormControl>
                                    
                                </Grid>
                              
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <FormControl variant="outlined" fullWidth name="newpassword" required
                                        value={newpassword} onChange={e => setNewpassword(e.target.value)}>
                                        <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                        <OutlinedInput
                                            labelWidth={140}
                                            type={newShowPasswordValue.showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleShowPassword2}
                                                        edge="end"
                                                    >
                                                        {newShowPasswordValue.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }

                                        />
                                    </FormControl>
                                   
                                </Grid>
                                   
                                <Grid
                                    item
                                    md={12}
                                    xs={12}
                                >
                                    <FormControl variant="outlined" fullWidth name="cpassword" required
                                        value={cpassword} onChange={e => setCpassword(e.target.value)}>
                                        <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                        <OutlinedInput
                                            labelWidth={140}
                                            type={cShowPasswordValue.showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleShowPassword3}
                                                        edge="end"
                                                    >
                                                        {cShowPasswordValue.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }

                                        />
                                    </FormControl>
                                    
                                </Grid>
                               

                                            </Grid>
                                        </CardContent>
                                        <Divider />
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            p={2}
                                        >
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className={classes.detailnew}
                                                onClick={handleFormData}
                                            >
                                                Change Password
          </Button>
                                        </Box>
                                    </Card>
                    </form>
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        xs={12}
                    >
                        <Card className={classes.cardarea2}>
                            <img src={pic}
                                title="student"
                                height="100px"
                                alt="student"
                            />
                            <CardContent className={classes.newcard}>
                                <Typography component="h5" variant="h4" className={classes.coursenroll} noWrap>
                                    <span className={classes.coursen}>ID :</span> {props.data.id}</Typography>
                                <Typography component="h5" variant="h4" className={classes.coursenroll} noWrap>
                                    <span className={classes.coursen}>Name :</span> {props.data.name}</Typography>
                                <Typography component="h5" variant="h4" className={classes.coursenroll} noWrap>
                                    <span className={classes.coursen}>Email :</span> {props.data.email}</Typography>
                                <Typography component="h5" variant="h4" className={classes.coursenroll} noWrap>
                                    <span className={classes.coursen}>Phone :</span> {props.data.phone}</Typography>
                               
                            </CardContent>
                        </Card>
                </Grid>
            </Grid>
                        </>
    );
}

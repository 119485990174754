import React,{useState} from "react";
import { Grid } from "@material-ui/core";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import General_English from "../../upload/General_English.png";
import IELTS from "../../upload/IELTS.png";
import Session from "../session/Session";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";

export default function CoursesPage() {
  var classes = useStyles();

  function showRazorpay()
  {
  var options = {
    "key": "rzp_test_lNTcZ5YPYrJaxe", 
    "amount": "500000",
    "currency": "INR",
    "name": "Little Letters Linked",
    "description": "IELTS Course",
    "handler": function (response){
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature)
    },
    "prefill": {
        "name": "John Smith",
        "email": "john.smith@example.com",
        "contact": "9999999999"
    },
    "notes": {
        "address": "Razorpay Corporate Office"
    },
    "theme": {
        "color": "#000"
    }
};
  const rpayment = new window.Razorpay(options);
  rpayment.open();
  }

  const [session, setsession] = useState(false);

  function handleClick(){
    setsession(true);
  }

  function getback() {
    setsession(false);
  }

  return (
    <>
    {
      session===true ? <Session getback={getback}/> :
      <>
      <PageTitle title="Courses" />
      <Typography variant="h4" className={classes.course}>Courses Enrolled</Typography>
      <Grid container spacing={4}>
        <Grid item lg={6} md={4} sm={6} xs={12}>
        <Card className={classes.root} alignItems="center">
        <CardMedia
        className={classes.cover}
        image={IELTS}
        title="course1"
        />
          <div className={classes.detail}>
            <CardContent className={classes.content}>
              <Typography component="h5" variant="h5" className={classes.coursen}>General English</Typography>
              <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
                <span className={classes.coursen}>Teacher :</span> Xyz</Typography>
              <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
                <span className={classes.coursen}>Batch :</span> GE03</Typography>
              <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
                <span className={classes.coursen}>Sessions :</span> 18/30</Typography>
                <Button size="small" className={classes.detailnew} variant="contained" onClick={handleClick}>Check Sessions</Button>
            </CardContent>
        </div>
        </Card>
      </Grid>
      </Grid>


      
      </>
    }
      
    </>
  );
}

import React,{Component} from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import SimpleReactValidator from "simple-react-validator";
import {history} from "./shared/history/history";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import API from "./shared/constants";

class Register extends Component {
  constructor()
  {
    super();
    this.state={
        name:"",
        email:"",
        phone:"",
        password:"",
        cpass:"",
        check:false
      }
      this.validator = new SimpleReactValidator({autoForceUpdate:this});
  }

  handleFormSubmit = e => {
    e.preventDefault();
    const { password, cpass } = this.state;
    if (password !== cpass) {
      alert("Passwords don't match");
    }
    else if(this.state.check === false)
    {
      alert("Please agree to the Terms and Conditions");
    }
     else if (this.validator.allValid()) {
      let data = {
          name: this.state.name,
          email: this.state.email,
          phone:this.state.phone,
          password: this.state.password
      };
      let config={
        headers:
        {
            "Content-type":"application/json"
        }
    }
    axios.post(`${API}/register`,JSON.stringify(data),config)
            .then(res=>{
                localStorage.setItem('token',res.data.token);
                localStorage.setItem('userid',res.data.id);
                  setTimeout(() => {
                     history.push("/app/dashboard");
                    window.location.reload();
                  }, 600);
                })
            .catch(err=>{
                const registerstatus=err.response.data.message.email;
                const registerstatus1=err.response.data.message.name
                const registerstatus2=err.response.data.message.phone;
                setTimeout(() => {
                this.setState({registerstatus,registerstatus1,registerstatus2})
              }, 100);
            })
    } 
    else {
      this.forceUpdate();
      this.validator.showMessages();
    }
    this.toggleLoading();
};

toggleLoading = () => {
  this.setState({ loading: true });

  setTimeout(() => {
    this.setState({ loading: false });
  }, 1000);
}
  
  handleInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = e => {
    this.setState({ check:e.target.checked});
  };

  render()
  {
    const { loading } = this.state;
  return (
    <>
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-md-7 d-none d-md-flex bg-image2">
          </div>
            <div className="col-md-5 bg-light">
              <div className="login d-flex align-items-center py-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 col-xl-9 mx-auto">
                      <div className="MuiAvatar-root MuiAvatar-circle jss40 MuiAvatar-colorDefault"><svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"></path></svg></div>
                      <h4 className="text-muted mb-4 text-center">Register</h4>
                      <form onSubmit={this.handleFormSubmit}>
                          <div className="form-group mb-3">
                            <input id="inputName" type="text" 
                            placeholder="Your Name" required="" autoFocus="" name="name" value={this.state.name}
                            onChange={this.handleInput} className="form-control rounded-pill border-0 shadow-sm px-4"/>
                          </div>
                          {
                            this.validator.message('name',this.state.name,'required|min:3')
                          }
                          <div className="form-group mb-3">
                            <input id="inputEmail" type="email" placeholder="Email address" 
                            required="" autoFocus="" name="email" value={this.state.email}
                            onChange={this.handleInput} className="form-control rounded-pill border-0 shadow-sm px-4" />
                          </div>
                          {
                            this.validator.message('email',this.state.email,'required|email')
                          }
                          <div className="form-group mb-3">
                            <input id="inputPhone" type="tel" placeholder="Phone No" 
                            required="" autoFocus="" name="phone" value={this.state.phone}
                            onChange={this.handleInput} className="form-control rounded-pill border-0 shadow-sm px-4"/>
                          </div>
                          {
                            this.validator.message('phone',this.state.phone,'required|phone')
                          }
                          <div className="form-group mb-3">
                          <input id="inputPassword" type="password" placeholder="Password" 
                          required="" name="password" value={this.state.password}
                          onChange={this.handleInput} className="form-control rounded-pill border-0 shadow-sm px-4 text-primary" />
                          </div>
                          {
                            this.validator.message('password',this.state.password,'required|min:8')
                          }
                           <div className="form-group mb-3">
                          <input id="inputcPassword" type="password" placeholder="Confirm Password" 
                          required="" name="cpass" value={this.state.cpass}
                          onChange={this.handleInput} className="form-control rounded-pill border-0 shadow-sm px-4 text-primary" />
                          </div>
                          {
                            this.validator.message('Confirm password',this.state.cpass,'required')
                          }
                          
                        <div className="custom-control custom-checkbox mb-3">
                          <input className="custom-control-input" type="checkbox" value=""
                            id="invalidCheck" required checked={this.state.check} onChange={this.handleChange}
                           />
                          <label className="custom-control-label" htmlFor="invalidCheck">
                            I agree to the Terms and Conditions * 
                          </label>
                        </div>
                            <button type="submit" className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm px-4 logbutton"
                            onClick={this.handleFormSubmit} disabled={loading}>
                              {loading && (<CircularProgress size={18} className="buttonProgress" />)}
                              Sign up</button>
                      </form>
                      {
                        this.state.registerstatus ? <Alert severity="error">{this.state.registerstatus}</Alert> : null}
                        {this.state.registerstatus1 ? <Alert severity="error">{this.state.registerstatus1}</Alert> : null}
                        {this.state.registerstatus2 ? <Alert severity="error">{this.state.registerstatus2}</Alert> : null
                      }
                      <div className="links">
                        <div className="float-right"><a href="/login" className="link-primary">Already have an account? Sign in</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
  }
}
export default Register;

import React from "react";

import PropTypes from 'prop-types';
import useStyles from "./styles";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


export default function SessionPage({getback}) {
  var classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  return (
    <>
      <PageTitle title="Session" />
      <Button size="small" className={classes.detailnew} variant="contained" 
      startIcon={<ArrowBackIcon />} onClick={getback}>Course</Button>
      <Typography variant="h4" className={classes.course}>Sessions : General English</Typography>
      <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <LinkTab label="Upcoming Sessions" href="/upcoming" {...a11yProps(0)} className={classes.tabnamebg}/>
          <LinkTab label="Completed Sessions" href="/completed" {...a11yProps(1)} className={classes.tabnamebg}/>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tabbackground}>
      
      <div className={classes.datacardtable}>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Session No:</span> 19</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Date:</span> 8/04/2021</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Time :</span> 6:00 PM</Typography>
      <Typography noWrap>
      <Button size="small" className={classes.detailnew} variant="contained" >Join Now</Button></Typography>
      </div>

      <div className={classes.datacardtable}>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Session No:</span> 20</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Date:</span> 10/04/2021</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Time :</span> 6:00 PM</Typography>
      <Typography noWrap>
      <Button size="small" className={classes.detailnew} variant="contained" >Join Now</Button></Typography>
      </div>

      <div className={classes.datacardtable}>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Session No:</span> 21</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Date:</span> 11/04/2021</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Time :</span> 6:00 PM</Typography>
      <Typography noWrap>
      <Button size="small" className={classes.detailnew} variant="contained" >Join Now</Button></Typography>
      </div>


      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabbackground}>
        
      <div className={classes.datacardtable}>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Session No:</span> 18</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Date:</span> 6/04/2021</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Time :</span> 6:00 PM</Typography>
      <Typography className={classes.coursetext} noWrap>
        <span className={classes.colortext1}> Completed</span></Typography>
      </div>

      <div className={classes.datacardtable}>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Session No:</span> 17</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Date:</span> 2/04/2021</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Time :</span> 6:00 PM</Typography>
      <Typography className={classes.coursetext} noWrap>
        <span className={classes.colortext1}> Completed</span></Typography>
      </div>

      <div className={classes.datacardtable}>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Session No:</span> 16</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Date:</span> 29/03/2021</Typography>
      <Typography component="h5" variant="h5" className={classes.coursenroll} noWrap>
        <span className={classes.coursen}>Time :</span> 6:00 PM</Typography>
      <Typography className={classes.coursetext} noWrap>
        <span className={classes.colortext1}> Completed</span></Typography>
      </div>

      </TabPanel>
    </div>
    </>
  );
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  
  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }
  
  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
}
}
